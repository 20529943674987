import { gsap } from "gsap";

export function initGetInTouchOverlay() {

    const getInTouchButtons = document.querySelectorAll('.get-in-touch-button');
    const getInTouchOverlay = document.querySelector('.get-in-touch-overlay');
    const closeOverlayButton = document.querySelector('.close-overlay');

    if (getInTouchButtons) {
        getInTouchButtons.forEach(button => {
            ['click', 'touchend'].forEach(eventType => {
                button.addEventListener(eventType, (e) => {
                    e.preventDefault();
                    e.stopPropagation();

                    document.body.style.overflowY = 'hidden';
                    gsap.to(getInTouchOverlay, {
                        opacity: 1,
                        duration: 0.5,
                        ease: 'expo.out',
                        onStart: () => {
                            getInTouchOverlay.style.display = 'flex';
                        }
                    });
                }, { passive: false });
            });
        });

        ['click', 'touchend'].forEach(eventType => {
            closeOverlayButton.addEventListener(eventType, (e) => {
                e.preventDefault();
                e.stopPropagation();

                gsap.to(getInTouchOverlay, {
                    opacity: 0,
                    duration: 0.5,
                    ease: 'expo.in',
                    onComplete: () => {
                        getInTouchOverlay.style.display = 'none';
                        document.body.style.overflowY = 'auto';

                        const nameField = getInTouchOverlay.querySelector('input[name="name"]');
                        const emailField = getInTouchOverlay.querySelector('input[name="email"]');
                        const messageField = getInTouchOverlay.querySelector('input[name="message"]');

                        getInTouchOverlay.querySelector('.get-in-touch').style.display = 'block';
                        getInTouchOverlay.querySelector('.get-in-touch').style.opacity = '1';
                        getInTouchOverlay.querySelector('.get-in-touch-text').style.display = 'block';
                        getInTouchOverlay.querySelector('.get-in-touch-text').style.opacity = '1';
                        getInTouchOverlay.querySelector('.submitted-text').style.display = 'none';
                        getInTouchOverlay.querySelector('.submit-text').style.display = 'block';
                        getInTouchOverlay.querySelector('.submit-text').style.opacity = '1';
                        getInTouchOverlay.querySelector('.loader').style.display = 'none';

                        if (nameField) nameField.value = '';
                        if (emailField) emailField.value = '';
                        if (messageField) messageField.value = '';
                    }
                });
            }, { passive: false });
        });
    }
}

