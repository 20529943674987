import { gsap } from "gsap";

export function initPitchToUsForm() {

	var pitchToUsForm = document.querySelector('.pitch-to-us-form');
	var pit = document.querySelector('.pit');

		if (pitchToUsForm) {

			const pitSubmitButton = pitchToUsForm.querySelector('button[type="submit"]');
			const pitSubmitText = pitSubmitButton.querySelector('.submit-text');
			const pitLoader = pitSubmitButton.querySelector('.loader');
			const pitSubmittedText = pit.querySelector('.submitted-text');
			const pitFormText = pit.querySelector('.form-text');

			var message = pitchToUsForm.querySelector('.message');
			var fields = {};
			pitchToUsForm.querySelectorAll('[name]').forEach(function (field) {
				fields[field.name] = field;
			});

			var handleError = function (response) {
				var errors = [];
				for (var key in response) {
					if (!response.hasOwnProperty(key)) continue;
					if (fields.hasOwnProperty(key)) fields[key].classList.add('error');
					Array.prototype.push.apply(errors, response[key]);
				}
				message.innerHTML = errors.join('<br>');
			}

			var onload = function (e) {
				if (e.target.status === 200) {
					// Reset all form fields
					for (var key in fields) {
						if (!fields.hasOwnProperty(key)) continue;
						fields[key].value = '';
					}

					gsap.to([pitchToUsForm, pitFormText], {
						opacity: 0,
						duration: 0.4,
						ease: "power2.out",
						onComplete: () => {
							pitchToUsForm.style.display = 'none';
							pitFormText.style.display = 'none';
							pitSubmittedText.style.display = 'block';
							pitSubmitButton.disabled = false;
							gsap.fromTo(pitSubmittedText,
								{ opacity: 0, y: 20 },
								{
									opacity: 1,
									y: 0,
									duration: 0.5,
									ease: "power2.out"
								}
							);
						}
					});
				} else {
					handleError(JSON.parse(e.target.response));
					if (pitSubmitButton) {
						pitSubmitButton.disabled = false;
					}
					pitSubmitText.style.display = 'block';
					pitLoader.style.display = 'none';
				}
			};

			var submit = function (e) {
				e.preventDefault();

				var submitButton = e.target.querySelector('button[type="submit"]');
				if (submitButton) {
					submitButton.disabled = true;
					pitSubmitText.style.display = 'none';
					pitLoader.style.display = 'block';
				}

				var request = new XMLHttpRequest();
				request.open('POST', e.target.action);
				request.onload = onload;
				request.send(new FormData(e.target));
				for (var key in fields) {
					if (!fields.hasOwnProperty(key)) continue;
					fields[key].classList.remove('error');
				}
			};
			pitchToUsForm.addEventListener('submit', submit);
		}
	}