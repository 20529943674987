import Flickity from "flickity";
import { gsap } from "gsap";
import Swup from "swup";
import SwupJsPlugin from "@swup/js-plugin";
import SwupHeadPlugin from '@swup/head-plugin';
import { SplitText } from "gsap/SplitText";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { initGetInTouchOverlay } from './getInTouchOverlay.js';
import { initGetInTouchForm } from './getInTouchForm.js';
import { initPitchToUsForm } from './pitchToUsForm.js';
import { initNewsletterForm } from './newsletterForm.js';
import { getCurrentUrl } from 'swup';

gsap.registerPlugin(SplitText, ScrollTrigger);

const swup = new Swup({
	animateHistoryBrowsing: true,
	scrollRestoration: false,
    plugins: [
		new SwupHeadPlugin(),
        new SwupJsPlugin({
            animations: [
                {
                    from: "(.*)",
                    to: "(.*)",
                    in: async () => {
                        await animatePageTransitionIn();
                    },
                    out: async (visit) => {
                        await animatePageTransitionOut();
                    },
                },
            ],
        }),
    ],
});

let wrapper;
let overlay;
let body;
let intentObserver;

function initTrigger() {

	if (typeof intentObserver !== 'undefined') {
        intentObserver.disable();
    }

	if (document.querySelector('.portfolio-section')) {
		const smiley = document.querySelector('.smiley');
		if (smiley) {
			gsap.to(smiley, {
				rotation: 360,
				duration: 1,
				scrollTrigger: {
					trigger: smiley,
					start: "clamp(top bottom)",
					end: "clamp(top top)",
					scrub: true,
				}
			});
		}
	}

	const spriteContainers = document.querySelectorAll('.spritecontainer');

	if (spriteContainers.length > 0) {
		spriteContainers.forEach((spriteContainer) => {
			const ladder = spriteContainer.querySelector('.ladder');
			const sprite = spriteContainer.querySelector('.sprite');

			if (sprite && ladder) {
				const spriteWidth = 200;
				const frameCount = 30;

				const tl = gsap.timeline({
					repeat: 3,
				});

				for (let i = 0; i < frameCount; i++) {
					const xPos = -i * spriteWidth;
					tl.to(sprite, {
						backgroundPosition: `${xPos}px 50%`,
						ease: 'steps(1)',
					});
				}

				ScrollTrigger.create({
					trigger: spriteContainer,
					start: 'top top',
					end: 'bottom bottom',
					scrub: 1,
					animation: tl,
				});

				gsap.to(ladder, {
					y: window.innerWidth < 500 ? '80%' : window.innerWidth < 900 ? '70%' : '84%',
					ease: 'none',
					scrollTrigger: {
						trigger: spriteContainer,
						start: 'top top',
						end: 'bottom bottom',
						scrub: true,
					}
				});
			}
		});
	}

	const header = document.getElementById('scroll-header');

	if(header) {
		ScrollTrigger.create({
			start: "top -1000",
			end: 99999,
			toggleClass: {
				targets: header,
				className: "visible"
			},
		});
	}

	const hero = document.querySelector('.hero');

	if(hero) {

		let heroTitle = document.querySelector('.hero .swipe-title');
		let heroText = document.querySelector('.hero .swipe-text');
		let splitHeroTitle = new SplitText(heroTitle, { type: "words" });

		let tl = gsap.timeline({
			defaults: { duration: 0.5, ease: "expo.inOut" },
		});

		gsap.set(hero, { autoAlpha: 1 });

		tl.fromTo(splitHeroTitle.words,
		{	yPercent: 100,	opacity: 0 },
		{
			yPercent: 0,
			opacity: 1,
			duration: 0.7,
			ease: "expo.out",
			stagger: 0.03
		})
		.fromTo(heroText,
		{ yPercent: 50, opacity: 0 },
		{
			yPercent: 0,
			opacity: 1,
			duration: 1,
			ease: "expo.out"
		}, 0.5);
	}

	const swipeHero = document.querySelector('.swipe-section');

	if(swipeHero) {
		let currentIndex = -1;
		let animating;
		let panels = gsap.utils.toArray(".swipe-section .panel");
		let headings = gsap.utils.toArray('.swipe-title');
		let texts = gsap.utils.toArray('.swipe-text');
		let layers = gsap.utils.toArray('.layer');
		let splitHeadings = headings.map(
			(headings) => new SplitText(headings, { type: "words" })
		);
		let wrap = gsap.utils.wrap(0, panels.length);

		const heroConfigs = {
			1: {
				images: [
					// Image 1
					{
						in: {
							initial: { yPercent: 50, opacity: 0 },
							animate: (img, timeline, delay) => {
								timeline.to(img, {
									yPercent: 0,
									opacity: 1,
									duration: 0.25,
									ease: "expo.out"
								}, delay);
							},
							startDelay: 0.2
						},
						out: {
							animate: (img, timeline, delay) => {
								timeline.to(img, {
									yPercent: 50,
									opacity: 0,
									duration: 0.15,
									ease: "expo.in"
								}, delay);
							},
							startDelay: 0.2
						}
					},
					// Image 2
					{
						in: {
							initial: { xPercent: -50, yPercent: 50, opacity: 0 },
							animate: (img, timeline, delay) => {
								timeline.to(img, {
									xPercent: 0,
									yPercent: 0,
									opacity: 1,
									duration: 0.25,
									ease: "expo.out"
								}, delay);
							},
							startDelay: 0.4
						},
						out: {
							animate: (img, timeline, delay) => {
								timeline.to(img, {
									xPercent: 50,
									yPercent: -50,
									opacity: 0,
									duration: 0.15,
									ease: "expo.in"
								}, delay);
							},
							startDelay: 0.4
						}
					},
					// Image 3
					{
						in: {
							initial: { xPercent: -50, yPercent: 50, opacity: 0 },
							animate: (img, timeline, delay) => {
								timeline.to(img, {
									xPercent: 0,
									yPercent: 0,
									opacity: 1,
									duration: 0.25,
									ease: "expo.out"
								}, delay);
							},
							startDelay: 0.4
						},
						out: {
							animate: (img, timeline, delay) => {
								timeline.to(img, {
									xPercent: -50,
									yPercent: 50,
									opacity: 0,
									duration: 0.15,
									ease: "expo.in"
								}, delay);
							},
							startDelay: 0.4
						}
					},
					// Image 4
					{
						in: {
							initial: { xPercent: -50, yPercent: 50, opacity: 0 },
							animate: (img, timeline, delay) => {
								timeline.to(img, {
									xPercent: 0,
									yPercent: 0,
									opacity: 1,
									duration: 0.25,
									ease: "expo.out"
								}, delay);
							},
							startDelay: 0.4
						},
						out: {
							animate: (img, timeline, delay) => {
								timeline.to(img, {
									xPercent: -50,
									yPercent: 50,
									opacity: 0,
									duration: 0.15,
									ease: "expo.in"
								}, delay);
							},
							startDelay: 0.4
						}
					},
					// Image 5
					{
						in: {
							initial: { xPercent: 50, opacity: 0 },
							animate: (img, timeline, delay) => {
								timeline.to(img, {
									xPercent: 0,
									opacity: 1,
									duration: 0.25,
									ease: "expo.out"
								}, delay);
							},
							startDelay: 0.7
						},
						out: {
							animate: (img, timeline, delay) => {
								timeline.to(img, {
									xPercent: 50,
									opacity: 0,
									duration: 0.15,
									ease: "expo.in"
								}, delay);
							},
							startDelay: 0.7
						}
					}
				]
			},
			2: {
				images: [
					{
						in: {
							initial: { yPercent: 50, opacity: 0 },
							animate: (img, timeline, delay) => {
								timeline.to(img, {
									yPercent: 0,
									opacity: 1,
									duration: 0.25,
									ease: "expo.out"
								}, delay);
							},
							startDelay: 0.2
						},
						out: {
							animate: (img, timeline, delay) => {
								timeline.to(img, {
									yPercent: 50,
									opacity: 0,
									duration: 0.15,
									ease: "expo.in"
								}, delay);
							},
							startDelay: 0.2
						}
					},
					{
						in: {
							initial: { yPercent: 50, opacity: 0 },
							animate: (img, timeline, delay) => {
								timeline.to(img, {
									yPercent: 0,
									opacity: 1,
									duration: 0.25,
									ease: "expo.out"
								}, delay);
							},
							startDelay: 0.4
						},
						out: {
							animate: (img, timeline, delay) => {
								timeline.to(img, {
									yPercent: 50,
									opacity: 0,
									duration: 0.15,
									ease: "expo.in"
								}, delay);
							},
							startDelay: 0.4
						}
					},
					{
						in: {
							initial: { yPercent: 50, opacity: 0 },
							animate: (img, timeline, delay) => {
								timeline.to(img, {
									yPercent: 0,
									opacity: 1,
									duration: 0.25,
									ease: "expo.out"
								}, delay);
							},
							startDelay: 0.6
						},
						out: {
							animate: (img, timeline, delay) => {
								timeline.to(img, {
									yPercent: 50,
									opacity: 0,
									duration: 0.15,
									ease: "expo.in"
								}, delay);
							},
							startDelay: 0.6
						}
					},
					{
						in: {
							initial: { yPercent: 50, opacity: 0 },
							animate: (img, timeline, delay) => {
								timeline.to(img, {
									yPercent: 0,
									opacity: 1,
									duration: 0.25,
									ease: "expo.out"
								}, delay);
							},
							startDelay: 0.8
						},
						out: {
							animate: (img, timeline, delay) => {
								timeline.to(img, {
									yPercent: 50,
									opacity: 0,
									duration: 0.15,
									ease: "expo.in"
								}, delay);
							},
							startDelay: 0.8
						}
					},
					{
						in: {
							initial: { yPercent: -50, opacity: 0 },
							animate: (img, timeline, delay) => {
								timeline.to(img, {
									yPercent: 0,
									opacity: 1,
									duration: 0.25,
									ease: "expo.out"
								}, delay);
							},
							startDelay: 0.6
						},
						out: {
							animate: (img, timeline, delay) => {
								timeline.to(img, {
									yPercent: -50,
									opacity: 0,
									duration: 0.15,
									ease: "expo.in"
								}, delay);
							},
							startDelay: 0.6
						}
					},
					{
						in: {
							initial: { yPercent: -50, opacity: 0 },
							animate: (img, timeline, delay) => {
								timeline.to(img, {
									yPercent: 0,
									opacity: 1,
									duration: 0.25,
									ease: "expo.out"
								}, delay);
							},
							startDelay: 1
						},
						out: {
							animate: (img, timeline, delay) => {
								timeline.to(img, {
									yPercent: -50,
									opacity: 0,
									duration: 0.15,
									ease: "expo.in"
								}, delay);
							},
							startDelay: 0.2
						}
					},
					{
						in: {
							initial: { yPercent: 50, opacity: 0 },
							animate: (img, timeline, delay) => {
								timeline.to(img, {
									yPercent: 0,
									opacity: 1,
									duration: 0.25,
									ease: "expo.out"
								}, delay);
							},
							startDelay: 0.6
						},
						out: {
							animate: (img, timeline, delay) => {
								timeline.to(img, {
									yPercent: 50,
									opacity: 0,
									duration: 0.15,
									ease: "expo.in"
								}, delay);
							},
							startDelay: 0.6
						}
					},
					{
						in: {
							initial: { yPercent: 50, opacity: 0 },
							animate: (img, timeline, delay) => {
								timeline.to(img, {
									yPercent: 0,
									opacity: 1,
									duration: 0.25,
									ease: "expo.out"
								}, delay);
							},
							startDelay: 0.8
						},
						out: {
							animate: (img, timeline, delay) => {
								timeline.to(img, {
									yPercent: 50,
									opacity: 0,
									duration: 0.15,
									ease: "expo.in"
								}, delay);
							},
							startDelay: 0.8
						}
					},
					{
						in: {
							initial: { yPercent: -50, opacity: 0 },
							animate: (img, timeline, delay) => {
								timeline.to(img, {
									yPercent: 0,
									opacity: 1,
									duration: 0.25,
									ease: "expo.out"
								}, delay);
							},
							startDelay: 0.6
						},
						out: {
							animate: (img, timeline, delay) => {
								timeline.to(img, {
									yPercent: -50,
									opacity: 0,
									duration: 0.15,
									ease: "expo.in"
								}, delay);
							},
							startDelay: 0.6
						}
					},
					{
						in: {
							initial: { yPercent: -50, opacity: 0 },
							animate: (img, timeline, delay) => {
								timeline.to(img, {
									yPercent: 0,
									opacity: 1,
									duration: 0.25,
									ease: "expo.out"
								}, delay);
							},
							startDelay: 0.3
						},
						out: {
							animate: (img, timeline, delay) => {
								timeline.to(img, {
									yPercent: -50,
									opacity: 0,
									duration: 0.15,
									ease: "expo.in"
								}, delay);
							},
							startDelay: 0.3
						}
					},

				]
			},
			3: {
				images: [
					{
						in: {
							initial: { xPercent: 50, opacity: 0 },
							animate: (img, timeline, delay) => {
								timeline.to(img, {
									xPercent: 0,
									opacity: 1,
									duration: 0.25,
									ease: "expo.out"
								}, delay);
							},
							startDelay: 0.2
						},
						out: {
							animate: (img, timeline, delay) => {
								timeline.to(img, {
									xPercent: 50,
									opacity: 0,
									duration: 0.15,
									ease: "expo.in"
								}, delay);
							},
							startDelay: 0.6
						}
					},
					{
						in: {
							initial: { opacity: 0 },
							animate: (img, timeline, delay) => {
								timeline.to(img, {
									opacity: 1,
									duration: 0.25,
									ease: "expo.out"
								}, delay);
							},
							startDelay: 0.4
						},
						out: {
							animate: (img, timeline, delay) => {
								timeline.to(img, {
									opacity: 0,
									duration: 0.15,
									ease: "expo.in"
								}, delay);
							},
							startDelay: 0.1
						}
					},
					{
						in: {
							initial: { yPercent: 50, opacity: 0 },
							animate: (img, timeline, delay) => {
								timeline.to(img, {
									yPercent: 0,
									opacity: 1,
									duration: 0.5,
									ease: "expo.out"
								}, delay);
							},
							startDelay: 0.6
						},
						out: {
							animate: (img, timeline, delay) => {
								timeline.to(img, {
									yPercent: 50,
									opacity: 0,
									duration: 0.15,
									ease: "expo.in"
								}, delay);
							},
							startDelay: 0.6
						}
					},
					{
						in: {
							initial: { yPercent: -50, opacity: 0 },
							animate: (img, timeline, delay) => {
								timeline.to(img, {
									yPercent: 0,
									opacity: 1,
									duration: 0.25,
									ease: "expo.out"
								}, delay);
							},
							startDelay: 0.6
						},
						out: {
							animate: (img, timeline, delay) => {
								timeline.to(img, {
									yPercent: -50,
									opacity: 0,
									duration: 0.15,
									ease: "expo.in"
								}, delay);
							},
							startDelay: 0.2
						}
					},
					{
						in: {
							initial: { opacity: 0 },
							animate: (img, timeline, delay) => {
								timeline.to(img, {
									opacity: 1,
									duration: 0.25,
									ease: "expo.out"
								}, delay);
							},
							startDelay: 0.4
						},
						out: {
							animate: (img, timeline, delay) => {
								timeline.to(img, {
									opacity: 0,
									duration: 0.15,
									ease: "expo.in"
								}, delay);
							},
							startDelay: 0.1
						}
					}
				]
			},
			4: {
				images: [
					{
						in: {
							initial: { xPercent: 50, opacity: 0 },
							animate: (img, timeline, delay) => {
								timeline.to(img, {
									xPercent: 0,
									opacity: 1,
									duration: 0.25,
									ease: "expo.out"
								}, delay);
							},
							startDelay: 0.2
						},
						out: {
							animate: (img, timeline, delay) => {
								timeline.to(img, {
									xPercent: 50,
									opacity: 0,
									duration: 0.15,
									ease: "expo.in"
								}, delay);
							},
							startDelay: 0.2
						}
					},
					{
						in: {
							initial: { yPercent: 50, opacity: 0 },
							animate: (img, timeline, delay) => {
								timeline.to(img, {
									yPercent: 0,
									opacity: 1,
									duration: 0.25,
									ease: "expo.out"
								}, delay);
							},
							startDelay: 0.2
						},
						out: {
							animate: (img, timeline, delay) => {
								timeline.to(img, {
									yPercent: 50,
									opacity: 0,
									duration: 0.15,
									ease: "expo.in"
								}, delay);
							},
							startDelay: 0.2
						}
					},
					{
						in: {
							initial: { yPercent: -50, opacity: 0 },
							animate: (img, timeline, delay) => {
								timeline.to(img, {
									yPercent: 0,
									opacity: 1,
									duration: 0.25,
									ease: "expo.out"
								}, delay);
							},
							startDelay: 0.4
						},
						out: {
							animate: (img, timeline, delay) => {
								timeline.to(img, {
									yPercent: -50,
									opacity: 0,
									duration: 0.15,
									ease: "expo.in"
								}, delay);
							},
							startDelay: 0.4
						}
					},
					{
						in: {
							initial: { yPercent: -50, opacity: 0 },
							animate: (img, timeline, delay) => {
								timeline.to(img, {
									yPercent: 0,
									opacity: 1,
									duration: 0.25,
									ease: "expo.out"
								}, delay);
							},
							startDelay: 0.5
						},
						out: {
							animate: (img, timeline, delay) => {
								timeline.to(img, {
									yPercent: -50,
									opacity: 0,
									duration: 0.15,
									ease: "expo.in"
								}, delay);
							},
							startDelay: 0.5
						}
					},
					{
						in: {
							initial: { yPercent: -50, opacity: 0 },
							animate: (img, timeline, delay) => {
								timeline.to(img, {
									yPercent: 0,
									opacity: 1,
									duration: 0.25,
									ease: "expo.out"
								}, delay);
							},
							startDelay: 0.6
						},
						out: {
							animate: (img, timeline, delay) => {
								timeline.to(img, {
									yPercent: -50,
									opacity: 0,
									duration: 0.15,
									ease: "expo.in"
								}, delay);
							},
							startDelay: 0.6
						}
					},
					{
						in: {
							initial: { yPercent: -50, opacity: 0 },
							animate: (img, timeline, delay) => {
								timeline.to(img, {
									yPercent: 0,
									opacity: 1,
									duration: 0.25,
									ease: "expo.out"
								}, delay);
							},
							startDelay: 0.7
						},
						out: {
							animate: (img, timeline, delay) => {
								timeline.to(img, {
									yPercent: -50,
									opacity: 0,
									duration: 0.15,
									ease: "expo.in"
								}, delay);
							},
							startDelay: 0.7
						}
					},
					{
						in: {
							initial: { xPercent: -50, opacity: 0 },
							animate: (img, timeline, delay) => {
								timeline.to(img, {
									xPercent: 0,
									opacity: 1,
									duration: 0.25,
									ease: "expo.out"
								}, delay);
							},
							startDelay: 0.2
						},
						out: {
							animate: (img, timeline, delay) => {
								timeline.to(img, {
									xPercent: -50,
									opacity: 0,
									duration: 0.15,
									ease: "expo.in"
								}, delay);
							},
							startDelay: 0.2
						}
					},
					{
						in: {
							initial: { yPercent: 50, opacity: 0 },
							animate: (img, timeline, delay) => {
								timeline.to(img, {
									yPercent: 0,
									opacity: 1,
									duration: 0.25,
									ease: "expo.out"
								}, delay);
							},
							startDelay: 0.6
						},
						out: {
							animate: (img, timeline, delay) => {
								timeline.to(img, {
									yPercent: 50,
									opacity: 0,
									duration: 0.15,
									ease: "expo.in"
								}, delay);
							},
							startDelay: 0.2
						}
					},
				]
			},
			5: {
				images: [
					{
						in: {
							initial: { yPercent: 50, opacity: 0 },
							animate: (img, timeline, delay) => {
								timeline.to(img, {
									yPercent: 0,
									opacity: 1,
									duration: 0.25,
									ease: "expo.out"
								}, delay);
							},
							startDelay: 0.2
						},
						out: {
							animate: (img, timeline, delay) => {
								timeline.to(img, {
									yPercent: 50,
									opacity: 0,
									duration: 0.15,
									ease: "expo.in"
								}, delay);
							},
							startDelay: 0.2
						}
					},
					{
						in: {
							initial: { xPercent: 50, opacity: 0 },
							animate: (img, timeline, delay) => {
								timeline.to(img, {
									xPercent: 0,
									opacity: 1,
									duration: 0.25,
									ease: "expo.out"
								}, delay);
							},
							startDelay: 0.4
						},
						out: {
							animate: (img, timeline, delay) => {
								timeline.to(img, {
									xPercent: 50,
									opacity: 0,
									duration: 0.15,
									ease: "expo.in"
								}, delay);
							},
							startDelay: 0.4
						}
					},
					{
						in: {
							initial: { xPercent: 50, opacity: 0 },
							animate: (img, timeline, delay) => {
								timeline.to(img, {
									xPercent: 0,
									opacity: 1,
									duration: 0.25,
									ease: "expo.out"
								}, delay);
							},
							startDelay: 0.5
						},
						out: {
							animate: (img, timeline, delay) => {
								timeline.to(img, {
									xPercent: 50,
									opacity: 0,
									duration: 0.15,
									ease: "expo.in"
								}, delay);
							},
							startDelay: 0.5
						}
					},
					{
						in: {
							initial: { xPercent: -50, opacity: 0 },
							animate: (img, timeline, delay) => {
									timeline.to(img, {
										xPercent: 0,
										opacity: 1,
										duration: 0.25,
										ease: "expo.out"
									}, delay);
								},
								startDelay: 0.6
						},
						out: {
							animate: (img, timeline, delay) => {
								timeline.to(img, {
									xPercent: -50,
									opacity: 0,
									duration: 0.15,
									ease: "expo.in"
								}, delay);
							},
							startDelay: 0.6
						}
					},
					{
						in: {
							initial: { xPercent: 50, opacity: 0 },
							animate: (img, timeline, delay) => {
								timeline.to(img, {
									xPercent: 0,
									opacity: 1,
									duration: 0.25,
									ease: "expo.out"
								}, delay);
							},
							startDelay: 0.7
						},
						out: {
							animate: (img, timeline, delay) => {
								timeline.to(img, {
									xPercent: 50,
									opacity: 0,
									duration: 0.15,
									ease: "expo.in"
								}, delay);
							},
							startDelay: 0.7
						}
					},
					{
						in: {
							initial: { xPercent: -50, opacity: 0 },
							animate: (img, timeline, delay) => {
								timeline.to(img, {
									xPercent: 0,
									opacity: 1,
									duration: 0.25,
									ease: "expo.out"
								}, delay);
							},
							startDelay: 0.8
						},
						out: {
							animate: (img, timeline, delay) => {
								timeline.to(img, {
									xPercent: -50,
									opacity: 0,
									duration: 0.15,
									ease: "expo.in"
								}, delay);
							},
							startDelay: 0.8
						}
					},
				]
			},
			6: {
				images: [
					{
						in: {
							initial: { yPercent: 50, opacity: 0 },
							animate: (img, timeline, delay) => {
								timeline.to(img, {
									yPercent: 0,
									opacity: 1,
									duration: 0.25,
									ease: "expo.out"
								}, delay);
							},
							startDelay: 0.4
						},
						out: {
							animate: (img, timeline, delay) => {
								timeline.to(img, {
									yPercent: -50,
									opacity: 0,
									duration: 0.15,
									ease: "expo.in"
								}, delay);
							},
							startDelay: 0.4
						}
					},
					{
						in: {
							initial: { yPercent: 50, opacity: 0 },
							animate: (img, timeline, delay) => {
								timeline.to(img, {
									yPercent: 0,
									opacity: 1,
									duration: 0.25,
									ease: "expo.out"
								}, delay);
							},
							startDelay: 0.2
						},
						out: {
							animate: (img, timeline, delay) => {
								timeline.to(img, {
									yPercent: 50,
									opacity: 0,
									duration: 0.15,
									ease: "expo.in"
								}, delay);
							},
							startDelay: 0.2
						}
					},
					{
						in: {
							initial: { xPercent: -50, opacity: 0 },
							animate: (img, timeline, delay) => {
								timeline.to(img, {
									xPercent: 0,
									opacity: 1,
									duration: 0.25,
									ease: "expo.out"
								}, delay);
							},
							startDelay: 0.6
						},
						out: {
							animate: (img, timeline, delay) => {
								timeline.to(img, {
									xPercent: -50,
									opacity: 0,
									duration: 0.15,
									ease: "expo.in"
								}, delay);
							},
							startDelay: 0.6
						}
					},
					{
						in: {
							initial: { xPercent: 50, opacity: 0 },
							animate: (img, timeline, delay) => {
								timeline.to(img, {
									xPercent: 0,
									opacity: 1,
									duration: 0.25,
									ease: "expo.out"
								}, delay);
							},
							startDelay: 0.6
						},
						out: {
							animate: (img, timeline, delay) => {
								timeline.to(img, {
									xPercent: 50,
									opacity: 0,
									duration: 0.15,
									ease: "expo.in"
								}, delay);
							},
							startDelay: 0.6
						}
					},
				]
			}
		};

		let heroAnimations = [];
		const heroContainers = document.querySelectorAll('.hero-animation');

		heroContainers.forEach((container) => {
			const heroIndex = parseInt(container.dataset.heroIndex);
			const images = container.querySelectorAll(`[class*="hero${heroIndex}-image-"]`);

			const config = heroConfigs[heroIndex];
			if (config) {
				images.forEach((img, idx) => {
					const imageConfig = config.images[idx];
					if (imageConfig?.in.initial) {
						gsap.set(img, imageConfig.in.initial);
					}
				});
			}

			heroAnimations[heroIndex] = {
				images: images,
				config: config
			};
		});

		intentObserver = ScrollTrigger.observe({
			type: "wheel,touch",
			onUp: () => !animating && gotoPanel(currentIndex + 1, true),
			onDown: () => !animating && gotoPanel(currentIndex - 1, false),
			wheelSpeed: -1,
			tolerance: 10,
			preventDefault: true,
			onPress: self => {
				ScrollTrigger.isTouch && self.event.preventDefault()
			}
		});
		intentObserver.disable();

		function gotoPanel(index, isScrollingDown) {
			animating = true;

			if ((index === panels.length && isScrollingDown) || (index === -1 && !isScrollingDown)) {
				let target = index;
				gsap.to(target, {
					duration: 0.00,
					onComplete: () => {
						animating = false;
						if (isScrollingDown) {
							intentObserver.disable();
							trigg.disable();

							document.querySelector('.swipe-section').style.overflow = 'hidden';

							gsap.set('.hero-animation', {
								willChange: 'transform',
								transform: 'translateZ(0)'
							});
						}
					}
				});
				return;
			}

			let tl = gsap.timeline({
				defaults: { duration: 0.5, ease: "expo.inOut" },
				onComplete: () => {
					animating = false;
				}
			});

			let nextIndex = wrap(index);
			let prevIndex = wrap(currentIndex);
			gsap.set(panels[nextIndex], {autoAlpha: 1});

			if(prevIndex === panels.length - 1 && isScrollingDown) {
				tl.fromTo(splitHeadings[nextIndex].words,
				{   yPercent: 100,   opacity: 0 },
				{
					yPercent: 0,
					opacity: 1,
					duration: 0.7,
					ease: "expo.out",
					stagger: 0.03
				})
				.fromTo(texts[nextIndex],
				{ yPercent: 50, opacity: 0 },
				{
					yPercent: 0,
					opacity: 1,
					duration: 1,
					ease: "expo.out"
				}, 0.5);

				const nextHero = heroAnimations[nextIndex + 1];
				if (nextHero?.config) {
					nextHero.images.forEach((img, idx) => {
						const imageConfig = nextHero.config.images[idx];
						if (imageConfig?.in.animate) {
							imageConfig.in.animate(img, tl, imageConfig.in.startDelay);
						}
					});
				}
			} else {
				tl.fromTo(splitHeadings[prevIndex].words,
					{ yPercent: 0, opacity: 1 },
					{
						yPercent: 100,
									opacity: 0,
									duration: 0.7,
						ease: "expo.out",
						stagger: 0.03
					})
				.fromTo(texts[prevIndex],
					{ yPercent: 0, opacity: 1 },
					{
						yPercent: 50,
									opacity: 0,
									duration: 0.7,
									ease: "expo.out"
					}, "<");

				const prevHero = heroAnimations[prevIndex + 1];
				if (prevHero?.config) {
					prevHero.images.forEach((img, idx) => {
						const imageConfig = prevHero.config.images[idx];
						if (imageConfig?.out.animate) {
							imageConfig.out.animate(img, tl, imageConfig.out.startDelay);
						}
					});
				}

				tl.addLabel("outAnimationsComplete", "+=0.1")

				.set(panels[prevIndex], { autoAlpha: 0 }, "outAnimationsComplete")

				.set(panels[nextIndex], { autoAlpha: 1 })
				.add(() => {
					const nextHero = heroAnimations[nextIndex + 1];
					if (nextHero?.config) {
						nextHero.images.forEach((img, idx) => {
							const imageConfig = nextHero.config.images[idx];
							if (imageConfig?.in.initial) {
								gsap.set(img, { ...imageConfig.in.initial, autoAlpha: 1 });
							}
						});
					}
				})

				.fromTo(splitHeadings[nextIndex].words,
					{ yPercent: 100, opacity: 0 },
					{
						yPercent: 0,
						opacity: 1,
						duration: 0.7,
						ease: "expo.out",
						stagger: 0.03
					}, "outAnimationsComplete+=0.2")
				.fromTo(texts[nextIndex],
					{ yPercent: 50, opacity: 0 },
					{
					yPercent: 0,
					opacity: 1,
					duration: 1,
					ease: "expo.out"
				}, "<");

				const nextHero = heroAnimations[nextIndex + 1];
				if (nextHero?.config) {
					nextHero.images.forEach((img, idx) => {
						const imageConfig = nextHero.config.images[idx];
						if (imageConfig?.in.animate) {
							imageConfig.in.animate(img, tl, `outAnimationsComplete+=${imageConfig.in.startDelay}`);
						}
					});
				}
			}

			currentIndex = index;
			intentObserver.enable();
		}

		let trigg = ScrollTrigger.create({
			trigger: ".swipe-section",
				pin: true,
				start: "top top",
				end: "+=1",
				onEnter: (self) => {
					intentObserver.enable();
					gotoPanel(currentIndex + 1, true);
				},
				onEnterBack: () => {
					intentObserver.enable();
					trigg.enable();
					document.querySelector('.swipe-section').style.overflow = 'visible';
				},
				onLeave: () => {
					intentObserver.disable();
				}
		});

		let next = document.querySelector('.next-button');

		if (next) {
			next.style.opacity = '0';
			next.style.pointerEvents = 'none';

			setTimeout(() => {
				gsap.to(next, {
					opacity: 1,
					duration: 1,
					ease: "expo.out",
					onStart: () => {
						next.style.pointerEvents = 'auto';
					},
					onComplete: () => {
						const handleNextClick = (e) => {
							e.preventDefault();
							intentObserver.disable();
							body.style.overflowY = 'scroll';
							gotoPanel(panels.length - 1, true);
							const nextElement = document.querySelector("#next");
							if (nextElement) {
								nextElement.scrollIntoView({
									behavior: "smooth"
								});
							}
						};

						next.addEventListener('click', handleNextClick);
						next.addEventListener('touchend', handleNextClick);
					}
				});
			}, 500);
		}


	}

	const fadeableElements = document.querySelectorAll('.fadeable');

	if (fadeableElements.length > 0) {
		fadeableElements.forEach(element => {
			gsap.set(element, { opacity: 0, yPercent: 10 });
			gsap.to(element, {
				scrollTrigger: {
					trigger: element,
					start: "0px 100%",
					markers: false,
				},
				opacity: 1,
				yPercent: 0,
				duration: 0.6,
				ease: "expo.inOut"
			});
		});
	}

	const splitImages = document.querySelectorAll('.image-clip-left, .image-clip-right');

	if (splitImages.length > 0) {
		splitImages.forEach(image => {
			const isLeft = image.classList.contains('image-clip-left');

			gsap.set(image, {
				xPercent: isLeft ? -100 : 100,
				opacity: 0
			});

			gsap.to(image, {
				scrollTrigger: {
					trigger: image.parentElement,
					start: "top 80%",
					end: "top 30%",
					scrub: 1
				},
				xPercent: 0,
				opacity: 1,
				duration: 1,
				ease: "expo.out"
			});
		});
	}

	const newsletterSection = document.querySelector('#newsletter');

	if (newsletterSection) {

		const mountains = newsletterSection.querySelector('#mountains');
		const fence = newsletterSection.querySelector('#fence');
		const dude = newsletterSection.querySelector('#dude');
		const newspaper = newsletterSection.querySelector('#newspaper');
		const newsletterForm = newsletterSection.querySelector('.newsletter-form');

		gsap.set([mountains, fence], {
			x: "50%",
			opacity: 0
		});
		gsap.set(dude, {
			x: "-50%",
			opacity: 0
		});
		gsap.set(newspaper, {
			x: "50%",
			opacity: 0
		});
		gsap.set(newsletterForm, {
			x: "-50%",
			opacity: 0
		});

		let tl = gsap.timeline({
			scrollTrigger: {
				trigger: newsletterSection,
				start: "top bottom",
				end: "top top",
			}
		});

		tl.to(mountains, {
			x: "0%",
			opacity: 1,
			duration: 0.8,
			ease: "expo.out"
		})
		.to(fence, {
			x: "0%",
			opacity: 1,
			duration: 0.8,
			ease: "expo.out"
		}, "-=0.6")
		.to(dude, {
			x: "0%",
			opacity: 1,
			duration: 0.8,
			ease: "expo.out"
		}, "-=0.6")
		.to(newsletterForm, {
			x: "0%",
			opacity: 1,
			duration: 0.8,
			ease: "expo.out"
		}, "-=0.6")
		.to(newspaper, {
			x: "0%",
			opacity: 1,
			duration: 0.8,
			ease: "expo.out"
		}, "-=0.6");
	}

	const toggleButtons = document.querySelectorAll('[onclick*="toggleSection"]');
	if (toggleButtons.length > 0) {
		toggleButtons.forEach(button => {
			const sectionId = button.getAttribute('onclick').match(/'([^']+)'/)[1];
			button.removeAttribute('onclick');

			button.addEventListener('click', () => {
				const element = document.getElementById(sectionId);
				element.classList.toggle('hidden');
			});
		});
	}

}

function initCategoryFilter() {
    const filterButtons = document.querySelectorAll('.category-filter');
    const storyItems = document.querySelectorAll('.story-item');
    const mobileFilterBtn = document.getElementById('mobile-filter-btn');
    const categoryFilters = document.querySelector('.category-filters');

    if (mobileFilterBtn) {
        mobileFilterBtn.addEventListener('click', () => {
            categoryFilters.classList.toggle('hidden');
            categoryFilters.classList.toggle('md:flex');
        });
    }

    filterButtons.forEach(button => {
        button.addEventListener('click', () => {
            const category = button.dataset.category;

            filterButtons.forEach(btn => btn.classList.remove('active'));
            button.classList.add('active');

            if (window.innerWidth < 768) {
                categoryFilters.classList.add('hidden');
                categoryFilters.classList.add('md:flex');
            }

            gsap.to(storyItems, {
                opacity: 0,
                duration: 0.3,
                onComplete: () => {
                    storyItems.forEach(item => {
                        if (category === 'all' || item.dataset.category === category) {
                            item.style.display = 'block';
                        } else {
                            item.style.display = 'none';
                        }
                    });

                    gsap.to(storyItems, {
                        opacity: 1,
                        duration: 0.3,
                        stagger: 0.05,
                        ease: "expo.out"
                    });
                }
            });
        });
    });
}

function initFaqAccordion() {
    const faqItems = document.querySelectorAll('.faq-item');

    faqItems.forEach(item => {
        const question = item.querySelector('.faq-question');
        const answer = item.querySelector('.faq-answer');
        const icon = item.querySelector('.faq-icon');

        question.addEventListener('click', () => {
            const isOpen = answer.style.maxHeight;

            faqItems.forEach(otherItem => {
                if (otherItem !== item) {
                    otherItem.querySelector('.faq-answer').style.maxHeight = null;
                    otherItem.querySelector('.faq-icon').textContent = '+';
                }
            });

            if (isOpen) {
                answer.style.maxHeight = null;
                icon.textContent = '+';
            } else {
                answer.style.maxHeight = answer.scrollHeight + 'px';
                icon.textContent = '-';
            }
        });
    });
}

function initListToggles() {
    const listHeadlines = document.querySelectorAll('.list_headline');
    listHeadlines.forEach(headline => {
        headline.addEventListener('click', () => {
            const listContent = headline.nextElementSibling;
            listContent.classList.toggle('hidden');
        });
    });
}

function init() {
    const loader = document.querySelector('.loader');
    gsap.to(loader, { opacity: 1, duration: 0.3 });

    window.addEventListener('load', () => {
        gsap.to(loader, {
            opacity: 0,
            duration: 0.3,
            onComplete: () => loader.style.display = 'none'
        });
    });

	wrapper = document.querySelector(".content-wrapper");
	overlay = document.querySelector(".swup-overlay");
	body = document.body;

	const burgers = document.querySelectorAll(".burger");
	const menu = document.querySelector(".menu");
	const closeButton = document.querySelector(".close-button");
	const carousels = document.querySelectorAll(".carousel");

	if (closeButton) {
		const closeHandler = function(e) {
			e.preventDefault();
			e.stopPropagation();

			gsap.killTweensOf(menu);
			gsap.to(menu, {
				x: "100%",
				duration: 0.2,
				ease: "none",
				onStart: () => {
					const menuItems = menu.querySelectorAll('.menu-link');
					gsap.set(menuItems, { opacity: 0, y: 20 });
				},
				onComplete: () => {
					menu.style.zIndex = '';
				}
			});
		};

		closeButton.removeEventListener('click', closeHandler);
		closeButton.removeEventListener('touchstart', closeHandler);

		closeButton.addEventListener('click', closeHandler, { passive: false });
		closeButton.addEventListener('touchstart', closeHandler, { passive: false });
	}
	if (carousels.length > 0) {
		carousels.forEach((carousel) => {
			const flkty = new Flickity(carousel, {
				pageDots: false,
				imagesLoaded: true,
				wrapAround: true,
				draggable: false,
				prevNextButtons: false,
				cellSelector: '.carousel-cell'
			});

			const prevButton = carousel.querySelector('.prev');
			const nextButton = carousel.querySelector('.next');

			if (prevButton) {
					prevButton.addEventListener('click', () => {
						flkty.previous();
					});
			}

			if (nextButton) {
					nextButton.addEventListener('click', () => {
						flkty.next();
					});
			}

		});
	}

	function resetMenuItems() {
		const menuItems = menu.querySelectorAll('.menu-link');
		gsap.set(menuItems, { opacity: 0, y: 20 });
	}

	if(menu) {
		gsap.set(menu, { x: "100%", display: "block" });
	}

	if (burgers.length && menu) {
		const menuItems = menu.querySelectorAll('.menu-link');
		resetMenuItems();

		burgers.forEach((burger) => {
			['click', 'touchend'].forEach(eventType => {
				burger.addEventListener(eventType, function(e) {
					e.preventDefault();
					e.stopPropagation();

					menu.style.zIndex = '9999';

					gsap.to(menu, {
						x: "0%",
						duration: 0.4,
						ease: "expo.out",
						onComplete: () => {
							gsap.to(menuItems, {
								opacity: 1,
								y: 0,
								duration: 0.4,
								stagger: 0.1,
								ease: "expo.out"
							});
						}
					});
				}, { passive: false });
			});
		});

		menuItems.forEach((item) => {
			['click', 'touchend'].forEach(eventType => {
				item.addEventListener(eventType, function(e) {
					e.preventDefault();
					e.stopPropagation();

					const href = this.getAttribute('href');

					if (this.classList.contains('get-in-touch-button')) {
						return;
					}

					gsap.to(menu, {
						x: "100%",
						duration: 0.2,
						ease: "expo.out",
						onComplete: () => {
							resetMenuItems();
							menu.style.zIndex = '';
							if (href) {
								swup.navigate(href);
							}
						}
					});
				}, { passive: false });
			});
		});
	}

	initListToggles();
	initCategoryFilter();
	initFaqAccordion();

    initGetInTouchOverlay();
	initGetInTouchForm();
	initPitchToUsForm();
	initNewsletterForm();

	const backButton = document.querySelector('.detail-back');
	if (backButton) {
		backButton.addEventListener('click', (e) => {
			e.preventDefault();
			const navigationHistory = JSON.parse(localStorage.getItem('navigationHistory') || '[]');

			if (navigationHistory.length > 1) {
				const previousUrl = navigationHistory[navigationHistory.length - 1];

				navigationHistory.pop();

				localStorage.setItem('navigationHistory', JSON.stringify(navigationHistory));

				swup.navigate(previousUrl, { history: 'replace' });
			} else {
				swup.navigate('/', { history: 'replace' });
				localStorage.setItem('navigationHistory', JSON.stringify(['/']));
			}
		});
	}
}

async function animatePageTransitionIn() {

    let tl = gsap.timeline({});

    window.scrollTo(0, 0);

    wrapper.style.transform = "translateY(240px)";
    gsap.set(overlay, {
        clipPath: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)"
    });

    await tl.to(wrapper, { y: 0, duration: 0.8, ease: "circ.inOut" })
    .to(overlay, {
        duration: 0.8,
        clipPath: "polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%)",
        ease: "circ.inOut",
		onStart: () => {
			const header = document.getElementById('scroll-header');
			if (header) {
				header.classList.remove('visible');
			}
		},
		onComplete: () => {
			ScrollTrigger.getAll().forEach(trigger => trigger.kill());
			initTrigger();
			setTimeout(() => {
                body.style.overflowY = 'scroll';
            }, 500);
		}
    }, 0);

    await setRandomTransitionImage();
}

async function animatePageTransitionOut() {

    let tl = gsap.timeline({});

    tl.set(overlay, {
        clipPath: "polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%)"
    });

    await tl.to(wrapper, { yPercent: -5, duration: 0.8, ease: "circ.inOut" })
    .to(overlay, {
        duration: 0.8,
        clipPath: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)",
        ease: "circ.inOut",
        onComplete: () => {
			body.style.overflowY = 'hidden';
            ScrollTrigger.killAll();
        }
    }, 0);
}

async function setRandomTransitionImage() {
    const assetsUrl = document.body.getAttribute('data-assets-url');
    const transitionImage = `transition${Math.floor(Math.random() * 9) + 1}.png`;
    const overlayImage = overlay.querySelector('img');
    overlayImage.src = `${assetsUrl}/images/transitions/${transitionImage}`;
}

document.addEventListener("DOMContentLoaded", () => {
    init();

    requestAnimationFrame(async () => {
        await animatePageTransitionIn();
    });
});


swup.hooks.on("page:view", () => {
    init();

    requestAnimationFrame(async () => {
        await animatePageTransitionIn();
    });

});

swup.hooks.on('link:click', () => {
	const currentUrl = getCurrentUrl();
	const navigationHistory = JSON.parse(localStorage.getItem('navigationHistory') || '[]');

	if (navigationHistory[navigationHistory.length - 1] !== currentUrl) {
		navigationHistory.push(currentUrl);
		localStorage.setItem('navigationHistory', JSON.stringify(navigationHistory));
	}
});

if (window.location.pathname === '/') {
	localStorage.setItem('navigationHistory', JSON.stringify(['/']));
} else {
	const existingHistory = localStorage.getItem('navigationHistory');
	if (!existingHistory) {
		localStorage.setItem('navigationHistory', JSON.stringify(['/']));
	}
}










