import { gsap } from "gsap";

export function initGetInTouchForm() {

	var getInTouchForm = document.querySelector('.get-in-touch');
	var git = document.querySelector('.git');

		if (getInTouchForm) {

			const loader = getInTouchForm.querySelector('.loader');
			const submittedText = git.querySelector('.submitted-text');
			const submitText = document.querySelector('.submit-text');
			const getInTouchText = document.querySelector('.get-in-touch-text');
			const submitButton = getInTouchForm.querySelector('button[type="submit"]');

			var message = getInTouchForm.querySelector('.message');
			var fields = {};
			getInTouchForm.querySelectorAll('[name]').forEach(function (field) {
				fields[field.name] = field;
			});

			var isAnimating = false;

			var handleError = function (response) {
				console.error('Form submission error:', response);
				var errors = [];
				for (var key in response) {
					if (!response.hasOwnProperty(key)) continue;
					if (fields.hasOwnProperty(key)) fields[key].classList.add('error');
					Array.prototype.push.apply(errors, response[key]);
				}
				message.innerHTML = errors.join('<br>');
				if (submitButton) {
					submitButton.disabled = false;
				}
				submitText.style.display = 'block';
				loader.style.display = 'none';
			}

			var onload = function (e) {
				if (e.target.status === 200 && !isAnimating) {
					isAnimating = true;

					var token_request = new XMLHttpRequest();
					token_request.open('GET', 'gettoken');
					token_request.onload = function() {
						if (token_request.status === 200) {
							var token = JSON.parse(token_request.response).token;
							var token_fields = getInTouchForm.querySelectorAll('input[name="csrf_token"]');
							for(var i = 0; i < token_fields.length; i++) {
								token_fields[i].value = token;
							}
						}
					};
					token_request.send();

					gsap.to([getInTouchForm, getInTouchText], {
						opacity: 0,
						duration: 0.4,
						ease: "power2.out",
						onComplete: () => {
							getInTouchText.style.display = 'none';
							submittedText.style.display = 'block';
							submitButton.disabled = false;
							getInTouchForm.reset();
							isAnimating = false;
						}
					});
				} else {
					console.error('Server response:', e.target.status, e.target.response);
					handleError(JSON.parse(e.target.response));
				}
			};

			var submit = function (e) {
				e.preventDefault();
				message.innerHTML = '';

				var submitButton = e.target.querySelector('button[type="submit"]');
				if (submitButton) {
						submitButton.disabled = true;
						submitText.style.display = 'none';
						loader.style.display = 'block';
				}

				var request = new XMLHttpRequest();
				request.open('POST', e.target.action);
					request.onload = onload;
					request.onerror = function() {
						console.error('Network error occurred during form submission');
						handleError({'general': ['An error occurred while submitting the form.']});
					};
				request.send(new FormData(e.target));
				for (var key in fields) {
					if (!fields.hasOwnProperty(key)) continue;
					fields[key].classList.remove('error');
				}
			};
			getInTouchForm.addEventListener('submit', submit);

			var token_request = new XMLHttpRequest();
			var request_path = 'gettoken';

			token_request.onreadystatechange = function() {
				var token_fields = getInTouchForm.querySelectorAll('input[name="csrf_token"]');

				if (this.status === 200 && this.readyState === 4) {
					var token = JSON.parse(this.response).token;
					for(var i = 0; i < token_fields.length; i++) {
						token_fields[i].value = token;
					}
				}
			};

			token_request.open('GET', request_path);
			token_request.send();

		}
	}

