import { gsap } from "gsap";

export function initNewsletterForm() {

		const newsletterForm = document.getElementById('newsletter-form');

		// newsletter form
		if(newsletterForm) {
			document.getElementById('newsletter-form').addEventListener('submit', function(e) {
				e.preventDefault();

				const email = document.getElementById('email');
				const privacyPolicy = document.getElementById('privacy-policy');
				const submitButton = document.getElementById('submit-button');
				const messageDiv = document.getElementById('message');
				const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
				const loader = newsletterForm.querySelector('.loader');
				const subscribedText = document.querySelector('.subscribed-text');
				const submitText = submitButton.querySelector('.submit-text');
				const newsletterText = document.querySelector('.newsletter-text');

				if (!emailRegex.test(email.value)) {
					messageDiv.textContent = 'Please enter a valid email address.';
					messageDiv.style.color = 'red';
					return;
				}

				if (!privacyPolicy.checked) {
					messageDiv.textContent = 'Please accept the privacy policy to subscribe.';
					messageDiv.style.color = 'red';
					return;
				}

				// Disable the button and change its text
				submitButton.disabled = true;
				submitText.style.display = 'none';
				loader.style.display = 'block';

				const formData = new FormData();
				formData.append('email', email.value);
				formData.append('metadata__first_name', first_name.value);
				formData.append('metadata__last_name', last_name.value);
				formData.append('embed', '1');

				fetch('https://buttondown.com/api/emails/embed-subscribe/lars.karsten', {
					method: 'POST',
					body: formData,
				})
				.then(response => {
					if (response.ok) {
						email.value = '';
						first_name.value = '';
						last_name.value = '';
						messageDiv.textContent = 'Thank you for subscribing!';
						messageDiv.style.color = 'green';
					} else {
						throw new Error('Subscription failed');
					}
				})
				.catch(error => {
					console.error('Error:', error);
					messageDiv.textContent = 'An error occurred. Please try again later.' + error;
					messageDiv.style.color = 'red';
				})
				.finally(() => {
					submitButton.disabled = false;
					submitText.style.display = 'block';
					loader.style.display = 'none';
					gsap.to(newsletterForm, {
						opacity: 0,
						duration: 0.4,
						ease: "power2.out",
						onComplete: () => {
							newsletterForm.style.display = 'none';
							newsletterText.style.display = 'none';
							subscribedText.style.display = 'block';
							gsap.fromTo(subscribedText,
								{ opacity: 0, y: 20 },
								{
									opacity: 1,
									y: 0,
									duration: 0.5,
									ease: "power2.out"
								}
							);
						}
					});
				});
			});
		}
	}
